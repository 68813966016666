<template>
    <el-card>
        <el-row :gutter="20">
            <el-col :span="12">
                <!-- 搜索与添加 -->
                <el-input 
                    v-model="search"
                    @clear="clearQuery"
                    placeholder="请输入内容" 
                    class="input-with-select" 
                    clearable>
                    <el-button slot="append" icon="el-icon-search" @click="searchRegion"></el-button>
                </el-input>
            </el-col>

            <el-col :span="4">
                <el-button type="primary" @click="addressDialogVisible = true">添加地区</el-button>
            </el-col>
        </el-row>
        
        <!-- 地区 -->
        <el-tabs class="mt20" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="item.name" :name=" '' + item.id" v-for="item in tabs" :key="item.id">    
                <el-scrollbar style="width:100%; height:500px">
                <el-table
                    :data="region"  
                    :load="load"
                    lazy
                    row-key="id"
                    size="small"
                    :tree-props="{hasChildren: 'hasChildren'}">
                    <el-table-column label="地区" prop="name"></el-table-column>
                    <el-table-column label="状态">
                        <template v-slot="scope">
                            <el-switch v-model="scope.row.status" @change="change({id: scope.row.id, status: scope.row.status ? 1 : 0})"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="热门城市">
                        <template v-slot="scope">
                            <el-switch v-model="scope.row.hot" @change="change({id: scope.row.id, hot: scope.row.hot ? 1 : 0})"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="等级">
                        <template v-slot="scope">
                            <el-tag v-if="scope.row.level === 1">{{scope.row.level}}</el-tag>
                            <el-tag size="small" type="warning" v-else-if="scope.row.level === 2">{{scope.row.level}}</el-tag>
                            <el-tag size="mini" type="danger" v-else>{{scope.row.level}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template v-slot="scope">
                            <el-button type="primary" icon="el-icon-edit" size="mini" @click="getRegion(scope.row.id)">{{$t('button.edit')}}</el-button>
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="remove(scope.row.id)">{{$t('button.del')}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                </el-scrollbar>
            </el-tab-pane>

            <el-tab-pane label="其他国家" name="all">敬请期待</el-tab-pane>
        </el-tabs>
        

        <!-- <el-cascader
            placeholder="搜索"
            :options="cityData"
            :props="{expandTrigger: 'hover'}"
            v-model="address"
            filterable>
        </el-cascader> -->

        <!-- 添加地区数据对话框 -->
        <el-dialog
            title="添加地区"
            :visible.sync="addressDialogVisible"
            @open="addChange"
            width="50%">
            <create-form 
                ref="add"
                size='medium'
                :createData="addData"
                :createForm="addForm"
                :rule="fromRules">
            </create-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addressDialogVisible = false">{{$t('button.cancel')}}</el-button>
                <el-button type="primary" @click="add">{{$t('button.ok')}}</el-button>
            </span>
        </el-dialog>

        <!-- 修改地址对话框 -->
        <el-dialog
            :title="$t('button.edit')"
            :visible.sync="editDialogVisible"
            width="50%">
            <create-form 
                ref="edit"
                size='medium'
                :createData="editData"
                :createForm="editForm"
                :rule="fromRules">
            </create-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">{{$t('button.cancel')}}</el-button>
                <el-button type="primary" @click="edit">{{$t('button.ok')}}</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import cityData from '@/assets/js/citydata.js'
import { getRegion, editRegion, changeRegion, parentdata} from '@/api/console/admin'

import CreateForm from '@/components/Form'
let level = [
                {label: '省', value: 1},
                {label: '市', value: 2},
                {label: '区/县', value: 3}
            ]

export default {
    data() {
        return {
            // 搜索
            search: '',
            // 默认
            activeName: '100000',
            tabs: [],
            // 地区
            region: [],
            // add 对话框
            addData: {
                level: 1
            },
            // edit 对话框
            editDialogVisible: false,
            editData: {},

            // 城市数据
            // cityData,
            address: [],

            // address show
            addressDialogVisible: false,

            propsRegion: {},
            parentAll: [],
            key: 0
        }
    },
    components: {
        CreateForm,
    },
    computed: {
        // add
        addForm() {
            return [
                { 
                    type: 'Input',
                    label: '地区',
                    prop: 'name',
                    placeholder: this.$t('form.region.name')
                },
                { 
                    type: 'Radio',
                    label: '等级',
                    prop: 'level',
                    props: {label: 'label', value: 'value'},
                    radios: level,
                    change: this.addChange,
                    placeholder: this.$t('form.region.level')
                },
                { 
                    type: 'Cascader',
                    label: '选择父级',
                    prop: 'cascader',
                    options: this.parentAll,
                    props: this.propsRegion,
                    key : this.key,
                    placeholder: '选择父级'
                },
                { 
                    type: 'Input',
                    label: '行政代码',
                    prop: 'code',
                    placeholder: this.$t('form.region.code')
                },
                { 
                    type: 'Switch',
                    label: '状态',
                    prop: 'status'
                },
                { 
                    type: 'Switch',
                    label: '热门城市',
                    prop: 'hot'
                }
            ]
        },
        editForm() {
            return [
                { 
                    type: 'Input',
                    label: '地区',
                    prop: 'name', 
                    placeholder: this.$t('form.region.name')
                },
                { 
                    type: 'Input', 
                    label: '父级', 
                    prop: 'fname',
                    placeholder: this.$t('form.region.name')
                },
                { 
                    type: 'Radio',
                    label: '等级',
                    prop: 'level',
                    props: {label: 'label', value: 'value'},
                    radios: level,
                    change: this.editChange,
                    placeholder: this.$t('form.region.level')
                },
                { 
                    type: 'Cascader',
                    label: '选择父级',
                    prop: 'fid',
                    options: this.parentAll,
                    props: this.propsRegion,
                    key : this.key,
                    placeholder: '选择父级'
                },
                // { 
                //     type: 'Select', 
                //     label: '等级', 
                //     prop:'level',
                //     options: level,
                //     props: { label: 'label', value: 'value'},
                //     change: row=>'',
                //     placeholder: this.$t('form.region.level')
                // },
                { 
                    type: 'Input', 
                    label: '行政代码',
                    prop: 'code',
                    placeholder: this.$t('form.region.code')
                },
                { 
                    type: 'Switch', 
                    label: '状态',
                    prop: 'status'
                },
                { 
                    type: 'Switch', 
                    label: '热门城市',
                    prop: 'hot'
                }
            ]
        },
        // 表单验证
        fromRules() {
            const rules = {
                name: [
                    { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
                    { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                ],
                level: [
                    { required: true, message: this.$t('validate.empty_radio'), trigger: 'blur' },
                ]
            }
            return rules
        }
    },
    async created() {
        this.tabs = await this.getRegionList()
    },
    mounted() {
        this.regionAll()
    },
    methods: {
        // 切换
        handleClick() {

        },
        // 搜索
        async searchRegion() {
            if(this.search) {
                let data = await this.getRegionList({query: this.search})
                data.forEach(e => {
                    e.hasChildren = false
                })

                return this.region = data
            }
            this.$message.warning(this.$t('tips.search'))
        },
        // 清空搜索
        clearQuery() {
            this.regionAll()
        },
        regionAll() {
            this.$nextTick( async () => {
                let data = {}
                if(this.search){
                    data.query = this.search
                } else {
                    data.fid = this.activeName
                }

                this.region = await this.getRegionList(data)
            })
        },
        getRegionList(data) {
            return new Promise(resolve => {
                getRegion(data).then(res => {
                    let list = res.data.data
                    list.forEach(element => {
                        element.status = element.status ? true : false
                        element.hot = element.hot ? true : false
                        element.hasChildren = element.level === 3 ? false : true
                    })
                    resolve(list)
                })
            })
        },
        async load(tree, treeNode, resolve) {
            let data = await this.getRegionList({fid: tree.id})
            resolve(data)
        },
        async lazyLoad(node, resolve) {
            let list = await this.getRegionList({fid: node.value})
            const nodes = list.map(item => ({
                ...item,
                label: item.name,
                value: item.id,
                leaf: item.level <= 2
            }))
            resolve(nodes)
        },
        // 获取当前选择的地区
        getRegion(id) {
            parentdata(id).then(res => {
                let data = res.data.data
                this.editData = {
                    level_1: data.parent.fid,
                    level_2: data.parent.id,
                    level_3: data.id,
                    name: data.name,
                    fname: data.parent.name,
                    fid: [data.parent.fid,data.parent.id,data.id],
                    level: data.level,
                    code: data.code,
                    status: data.status ? true : false,
                    hot: data.hot? true : false
                }
                this.getParent(data.level)
                this.editDialogVisible = true
                this.$nextTick(() => { 
                    this.$refs.edit.clearValidate()
                })
            })
        },
        // 状态切换
        change(data) {
            console.log(data)
            changeRegion(data).then(res => {
                this.$message.success(res.data.msg)
                this.regionAll()
            })
        },
        // 删除
        remove(data) {
            this.$nextTick( async () => {
                let data = await this.getRegionList({fid: this.activeName})
                console.log(data)
            })
        },
        // 获取当前等级下的分类信息
        async getParent(level) {
            this.propsRegion = {
                value: 'id',
                label: 'name',
            }
            let data = {
                fid: this.activeName
            }
            switch(level){
                case 1:
                    ++this.key //cascader重新渲染
                    data = ''
                    // this.$message.warning('等级为省（直辖市）不能选择该选项')
                    break
                case 2:
                    break
                case 3:
                    this.propsRegion = {
                        value: 'id',
                        label: 'name',
                        lazy: true,
                        lazyLoad: this.lazyLoad
                    }
                    break
                default:
                    break
            }
            // this.parentAll = data ? await this.getRegionList(data) : []
            this.parentAll = await this.getRegionList(data)
        },
        addChange() {
            let level =  this.addData.level
            this.addData.cascader = []
            this.getParent(level)
        },
        editChange() {
            let level =  this.editData.level
            this.getParent(level)
        },
        // 添加
        async add() {
            let res = await this.$refs.add.validate()
            console.log(res)
        },
        // 修改
        async edit() {
            let res = await this.$refs.edit.validate()
            if(res) {
                editRegion(this.editData).then( res => {
                    this.$message.success(res.data.msg)
                    this.editDialogVisible = false
                    this.regionAll()
                })
            }
        },
    }
}
</script>